/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { Link, graphql, withPrefix } from "gatsby";
import LazyHero from "react-lazy-hero";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import HeroContentContainer from "../../components/HeroContentContainer";

const StudentParents = ({ data, ...props }) => {
  const stories = data.allMarkdownRemark.edges;
  const whoAreThey = data.allWhoAreTheyJson.edges;
  const supportingStudentParents = data.allSupportingStudentParentsJson.edges;
  const barriers = data.allBarriersJson.edges;
  return (
    <Layout bodyClass="page-about-risers" {...props}>
      <SEO title="About Risers" />
      <LazyHero
        className="hero--secondary"
        imageSrc={withPrefix("/heroes/about-the-risers.jpg")}
        isCentered={false}
        minHeight="40vh"
        opacity={0.2}
        color="#4fd1a2"
      >
        <HeroContentContainer>
          <h1>About Risers</h1>
        </HeroContentContainer>
      </LazyHero>

      <div className="container pt-2 pt-md-6 pb-2 pb-md-4">
        <div className="row">
          <div className="col-12">
            <h2 className="font-weight-bolder">Why Risers?</h2>
            <p>
              The demographics of students in higher education are evolving.
              Today&apos;s postsecondary students are older, more financially
              independent, and more likely to be raising children than ever
              before. Student parents are often overlooked, despite making up
              nearly a quarter of the student body, with many colleges unaware
              of the number of student parents enrolled. Current systems and
              structures prioritize “traditional” students—18 year olds,
              arriving directly from high school.
            </p>
          </div>
          <div className="col-12">
            <p>
              This is a lost opportunity. Student parents are a vital and
              valuable part of the postsecondary system. They are exceptionally
              motivated to succeed, for both themselves and their children.{" "}
              <strong>This is why we call them Risers</strong>. Risers are
              resilient and resourceful, and they outperform students without
              children. With the right support, they can realize their
              incredible potential.
            </p>
            <p>
              By acknowledging the existence of Risers, understanding their
              unique needs and strengths, and actively seeking to improve the
              likelihood of their completion, we can ensure the success of
              student parents and create a path for families that leads to
              increased economic mobility, improved well-being, and overall
              better outcomes for parents and their children.
            </p>
          </div>
        </div>
      </div>

      <div id="risers" className="container pb-4 pb-md-2">
        <h2 className="font-weight-bolder mb-2">Raising Dreams</h2>
        <div className="embedtool">
          <iframe
            src="https://www.youtube.com/embed/fzkkYEzjvm8"
            frameBorder="0"
            allowFullscreen
            title="Raising Dreams"
          />
        </div>
        <p className="mt-2 mb-0 mb-md-4">
          From the filmmakers behind <em>First Generation</em> and <em>Unlikely</em> comes the
          short film{" "}
          <a
            className="text-blue text-underline"
            href="https://www.youtube.com/watch?v=fzkkYEzjvm8&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Raising Dreams
          </a>
          , an intimate portrait of two student parents on their postsecondary
          journey. This documentary featurette uncovers the challenges and
          triumphs as well as the need for innovative solutions to ensure
          parents have the support to complete their degrees.
        </p>
      </div>
      <div id="risers" className="container pb-2 pb-md-6">
        <h2 className="font-weight-bolder">Learn More</h2>
        <p className="mb-0 mb-md-4">
          Learn about the realities and resourcefulness of Risers.
        </p>
        <div className="row">
          {stories.map(edge => (
            <article
              className="story-teaser col-12 col-md-6 col-lg-4 mb-1 mt-2 mt-md-0"
              key={edge.node.frontmatter.path}
            >
              <div className="card">
                <div className="card-content">
                  <img
                    className="story-image"
                    src={withPrefix(edge.node.frontmatter.image)}
                    alt={edge.node.frontmatter.name}
                  />
                  <h4 className="story-name">
                    Meet {edge.node.frontmatter.name}
                  </h4>
                  <p className="story-excerpt">{edge.node.excerpt}</p>
                  <Link
                    className="story-cta text-uppercase text-blue"
                    to={edge.node.frontmatter.path}
                    onClick={() => {
                      trackCustomEvent({
                        category: "Riser",
                        action: "Click",
                        label: `${edge.node.frontmatter.name}`,
                      });
                    }}
                  >
                    <span>View Story</span>
                    <img
                      className="chevron-right"
                      src={withPrefix("/icons/chevron-right.svg")}
                      alt="chevron right"
                      role="presentation"
                    />
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
      <div className="bg-orange">
        <div className="container pt-5 pt-md-7 pb-0">
          <h2 className="text-center font-weight-bolder">
            Student Parents: Who are they?
          </h2>
          <div className="row justify-content-center py-4 py-md-6">
            {whoAreThey.map(edge => (
              <div key={edge.node.id} className="col-12 col-md-6 mb-3 px-3">
                {edge.node.image && (
                  <React.Fragment>
                    <div className="data-vis">
                      <div className="data-vis-image">
                        <img src={withPrefix(edge.node.image)} alt="" />
                      </div>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: edge.node.description,
                      }}
                    />
                    {edge.node.source && (
                      <p className="data-vis-source">
                        <span>Source:</span>{" "}
                        <span
                          dangerouslySetInnerHTML={{ __html: edge.node.source }}
                        />
                      </p>
                    )}
                  </React.Fragment>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container pt-5 pt-md-7 pb-0">
        <h2 className="text-center font-weight-bolder">
          Supporting student parents drives economic mobility:
        </h2>
        <div className="row justify-content-center py-4 py-md-6">
          {supportingStudentParents.map(edge => (
            <div key={edge.node.id} className="col-12 col-md-6 mb-3 px-3">
              {edge.node.image && (
                <React.Fragment>
                  <div className="data-vis">
                    <div className="data-vis-image">
                      <img src={withPrefix(edge.node.image)} alt="" />
                    </div>
                  </div>
                  <p
                    dangerouslySetInnerHTML={{ __html: edge.node.description }}
                  />
                  {edge.node.source && (
                    <p className="data-vis-source">
                      <span>Source:</span>{" "}
                      <span
                        dangerouslySetInnerHTML={{ __html: edge.node.source }}
                      />
                    </p>
                  )}
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bg-light-green">
        <div className="container pt-5 pt-md-7 pb-0">
          <h2 className="text-center font-weight-bolder">
            But they face many barriers:
          </h2>
          <div className="row justify-content-center py-4 py-md-6">
            {barriers.map(edge => (
              <div key={edge.node.id} className="col-12 col-md-6 mb-3 px-3">
                {edge.node.image && (
                  <React.Fragment>
                    <div className="data-vis">
                      <div className="data-vis-image">
                        <img src={withPrefix(edge.node.image)} alt="" />
                      </div>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: edge.node.description,
                      }}
                    />
                    {edge.node.source && (
                      <p className="data-vis-source">
                        <span>Source:</span>{" "}
                        <span
                          dangerouslySetInnerHTML={{ __html: edge.node.source }}
                        />
                      </p>
                    )}
                  </React.Fragment>
                )}
              </div>
            ))}
            <div className="col-12 mt-3">
              <h2 className="text-center">
                <span className="font-weight-bold d-block pb-1">
                  Student parents deserve more.
                </span>
                <span className="font-weight-bold font-alfa">
                  You can help!
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/stories/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            name
            path
            image
          }
        }
      }
    }
    allWhoAreTheyJson {
      edges {
        node {
          id
          title
          description
          image
          source
        }
      }
    }
    allSupportingStudentParentsJson {
      edges {
        node {
          id
          title
          description
          image
          source
        }
      }
    }
    allBarriersJson {
      edges {
        node {
          id
          title
          description
          image
          source
        }
      }
    }
  }
`;

export default StudentParents;
